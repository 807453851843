var box_typ_01 = function () {
    $('.box_typ_01 .ce_text').matchHeight();

    var box_typ_01_padding = 15;

    $('.box_typ_01 .inner_border').css('width', function () {
        return $('.box_typ_01 .inner').width() - (2 * box_typ_01_padding);
    });

    $('.box_typ_01 .inner_border').css('height', function () {
        return $('.box_typ_01 .inner').height() - (2 * box_typ_01_padding);
    });

    $('.box_typ_01').css('opacity', 1);
};

var box_typ_02 = function () {
    $('.box_typ_02 .ce_headline').matchHeight();
    $('.box_typ_02 .ce_text').matchHeight();

    $('.box_typ_02').css('opacity', 1);
};


$(document).ready(function () {
    box_typ_01();
    box_typ_02();
    $('.img-slider').slick({
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        autoplay: false,
        autoplaySpeed: 4000,
        arrows: true,
        dots: false
    });

});

$(window).resize(function () {
    box_typ_01();
    box_typ_02();
});