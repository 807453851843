$(document).ready(function () {

    $('body').scrollspy({target: ".navbar", offset: $('.navbar').height() - 11});

    $(".navbar .nav-link").on('click', function (event) {
//        if (this.hash !== "") {
            event.preventDefault();
//            var hash = this.hash;
            var hash = $(this).attr('data-attr-scroll');

            $('html, body').animate({
                scrollTop: $(hash).offset().top - $('.navbar').height() + 12
            }, 2500, 'easeOutQuint', function () {

//                if (history.pushState) {
//                    history.pushState(null, null, hash);
//                } else {
//                    location.hash = hash;
//                }
//            window.location.hash = hash;
            });
//        }
    });

    $('.navbar-nav>li>a').on('click', function () {
        $('.navbar-collapse').collapse('hide');
    });
});

$(window).scroll(function () {
    if ($(this).scrollTop() > 10) {
        $('.navbar').addClass('scrolled');
    } else {
        $('.navbar').removeClass('scrolled');
    }
});